import React from "react";
import './header.css';

export function Header() {
    return (
        <header id="header" className="fixed-top d-flex align-items-center header-transparent">
            <div className="container d-flex align-items-center">

                <div className="logo mr-auto">
                    <h1 className="text-light">
                        <a href="#hero"><span>Leverage Point Innovations</span></a>
                    </h1>
                    {/*Uncomment below if you prefer to use an image logo */}
                    {/*<a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>*/}
                </div>

                <nav className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><a href="#hero">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#gallery">Portfolio</a></li>
                        <li><a href="#footer">Contact</a></li>
                    </ul>
                </nav>

            </div>
        </header>
    );
}
