import React from "react";
import './footer.css';

export function Footer() {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="footer-info">
                                <h3>Leverage Point Innovations</h3>
                                <p className="pb-3">
                                    <em>Delivering tangible sustainable growth</em>
                                </p>
                                <p>
                                    The Design Quarter<br/>
                                    Cnr. William Nicol & Leslie Avenue<br/>
                                    Fourways, 2055<br/>
                                    <strong>Phone:</strong> <a href={'tel:0115133236'} target="_blank"
                                                               rel="noopener noreferrer">+27 11 513 3236</a><br/>
                                    <strong>Email:</strong> <a
                                    href={'mailto:info@leveragepoint.co.za'} target="_blank"
                                    rel="noopener noreferrer">info@leveragepoint.co.za</a><br/>
                                </p>
                            </div>
                        </div>

                        <div className
                                 ="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Home</a>
                                </li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#about">About Us</a>
                                </li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#gallery">Portfolio</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Management Consulting</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Procurement Management</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Project Management</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Mergers & Acquisitions</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <ul style={{marginTop: '40px'}}>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Financial Management</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Property Management</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Business Information Technology &
                                        Systems Management</a></li>
                                <li><i className="bx bx-chevron-right"/>
                                    <a href="#">Corporate Social Investment
                                        Consulting</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Leverage Point Innovations</span></strong>. All Rights Reserved
                </div>
                {/*<div className="credits"> Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a></div>*/}
                <div className="credits"> Developed by <a href="https://www.yelah.co.za" target="_blank"
                                                          rel="noopener noreferrer">Yelah</a></div>
            </div>
        </footer>
    );
}
