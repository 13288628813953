import './App.css';
import React from "react";
import {Header} from "./Header/Header";
import {Hero} from "./Hero/Hero";
import {Footer} from "./Footer/Footer";
import {About} from "./About/About";
import {Portfolio} from "./Portfolio/Portfolio";

import jQuery from 'jquery';
import 'jquery.easing';

export default function App() {

    (function ($) {
        const setMobileNav = () => {
            // Smooth scroll for the navigation menu and links with .scrollto classes
            const scrollToOffset = $('#header').outerHeight() - 21;
            const body = $('body');
            $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
                const target = $(this.hash);
                if (target.length) {
                    e.preventDefault();

                    let scrollTo = target.offset().top - scrollToOffset;

                    if ($(this).attr("href") === '#header') {
                        scrollTo = 0;
                    }

                    $('html, body').animate({
                        scrollTop: scrollTo
                    }, 1500, 'easeInOutExpo');

                    if ($(this).parents('.nav-menu, .mobile-nav').length) {
                        $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                        $(this).closest('li').addClass('active');
                    }

                    if (body.hasClass('mobile-nav-active')) {
                        body.removeClass('mobile-nav-active');
                        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                        $('.mobile-nav-overly').fadeOut();
                    }
                    return false;
                }
            });

            $(document).ready(function () {
                if (window.location.hash) {
                    var initial_nav = window.location.hash;
                    if ($(initial_nav).length) {
                        var scrollto = $(initial_nav).offset().top - scrollToOffset;
                        $('html, body').animate({
                            scrollTop: scrollto
                        }, 1500, 'easeInOutExpo');
                    }
                }
            });

            // Mobile Navigation
            const navMenu = $('.nav-menu');
            if (navMenu.length) {
                const $mobile_nav = navMenu.clone().prop({
                    class: 'mobile-nav d-lg-none'
                });
                body.append($mobile_nav);
                body.prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
                body.append('<div class="mobile-nav-overly"></div>');

                $(document).on('click', '.mobile-nav-toggle', function (e) {
                    body.toggleClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').toggle();
                });

                $(document).click(function (e) {
                    const container = $(".mobile-nav, .mobile-nav-toggle");
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        if (body.hasClass('mobile-nav-active')) {
                            body.removeClass('mobile-nav-active');
                            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                            $('.mobile-nav-overly').fadeOut();
                        }
                    }
                });
            } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
                $(".mobile-nav, .mobile-nav-toggle").hide();
            }
        }

        // Navigation active state on scroll
        const nav_sections = $('section');
        const main_nav = $('.nav-menu, .mobile-nav');

        // Toggle .header-scrolled class to #header when page is scrolled
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#header').addClass('header-scrolled');
            } else {
                $('#header').removeClass('header-scrolled');
            }

            const cur_pos = $(this).scrollTop() + 200;

            nav_sections.each(function () {
                const top = $(this).offset().top,
                    bottom = top + $(this).outerHeight();

                if (cur_pos >= top && cur_pos <= bottom) {
                    if (cur_pos <= bottom) {
                        main_nav.find('li').removeClass('active');
                    }
                    main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
                }
                if (cur_pos < 300) {
                    $(".nav-menu ul:first li:first, .mobile-menu ul:first li:first").addClass('active');
                }
            });
        });

        if ($(window).scrollTop() > 100) {
            $('#header').addClass('header-scrolled');
        }

        // Back to top button
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });

        $('.back-to-top').click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 1500, 'easeInOutExpo');
            return false;
        });

        $(window).on('load', () => {
            const preLoader = $('#preloader');
            if (preLoader.length) {
                preLoader.delay(100).fadeOut('slow', function () {
                    $(this).remove();
                });
            }

            setMobileNav();
        });
    })(jQuery);

    return (
        <>
            <Header/>
            <Hero/>
            <main id="main">
                <About/>
                <Portfolio/>
            </main>
            <a href="#" className="back-to-top"><i className="icofont-simple-up"/></a>
            <div id="preloader"/>
            <Footer/>
        </>);
}

