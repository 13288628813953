import React from "react";
import {gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8} from './gallery';

import './portfolio.css';

export function Portfolio() {
    return (
        <section id="gallery" className="gallery">
            <div className="container">

                <div className="section-title" data-aos="fade-up">
                    <h2>Portfolio</h2>
                    <p>Check our Portfolio</p>
                </div>

                <div className="row no-gutters" data-aos="fade-left">

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="100">
                            <a href={gallery1} className="venobox" data-gall="gallery-item">
                                <img src={gallery1} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="150">
                            <a href={gallery2} className="venobox" data-gall="gallery-item">
                                <img src={gallery2} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="200">
                            <a href={gallery3} className="venobox" data-gall="gallery-item">
                                <img src={gallery3} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="250">
                            <a href={gallery4} className="venobox" data-gall="gallery-item">
                                <img src={gallery4} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="300">
                            <a href={gallery5} className="venobox" data-gall="gallery-item">
                                <img src={gallery5} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="350">
                            <a href={gallery6} className="venobox" data-gall="gallery-item">
                                <img src={gallery6} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="400">
                            <a href={gallery7} className="venobox" data-gall="gallery-item">
                                <img src={gallery7} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                            <a href={gallery8} className="venobox" data-gall="gallery-item">
                                <img src={gallery8} alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}
