import React from "react";
import './about.css';

export function About() {
    return (
        <section id="about" className="about">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                         data-aos="fade-right">
                    </div>

                    <div
                        className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
                        data-aos="fade-left">
                        <h3>About Leverage Point</h3>
                        <p>Leverage Point is a company focused on management consulting in finance, technology services
                            and business process outsourcing. We combine unparalleled experience, comprehensive
                            capabilities across all industries. We carry out extensive research on the world's most
                            successful companies, institutions, and the government that equips us with high end skills
                            to collaborate with clients to help them become high-performance businesses and
                            governments.</p>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-paint"/></div>
                            <h4 className="title"><a href="">Focused Creativity</a></h4>
                            <p className="description">Together we will explore creative options you would not have
                                envisaged in your day to day operations. We then evaluate those options based on your
                                goals not subjective to trendy styles. We will take calculated and unexpected creative
                                risks to find the perfect fit to visually articulate your desired goals.</p>
                        </div>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon"><i className="bx bx-bulb"/></div>
                            <h4 className="title"><a href="">Innovation</a></h4>
                            <p className="description">Driven by re-looking and breaking down the impossible idea, and
                                developing a focused and detailed observation. We will study the problem, assess the
                                opportunities, understand your organization and apply our expertise to your unique
                                challenge.</p>
                        </div>

                        <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon"><i className="bx bx-trophy"/></div>
                            <h4 className="title"><a href="">Winning Strategy</a></h4>
                            <p className="description">In all our service offerings, we will always devise and develop a
                                unique strategy that requires our disciplined and proven strategy to put your ideas a
                                step ahead of your opponents and emerge with winning methods.</p>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
}
